import { IProject } from "../../../api/projects";
import DV360Resource, { IDV360Advertiser } from "../../../api/dv360";
import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { Save } from "@mui/icons-material";
import { RoutePaths } from "../../../routes";
import { Link as RouterLink } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { axios500Error, successMessage } from "content/projects";

interface ProjectEditDV360Props {
  project: IProject;
}

const ProjectEditDV360 = (props: ProjectEditDV360Props) => {
  const [advertiser, setAdvertiser] = useState<Partial<IDV360Advertiser>>({});
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  async function fetchAdvertiser(projectId: number) {
    const response = await DV360Resource.getAdvertiser(projectId);
    setAdvertiser(response);
  }

  async function saveAdvertiser(
    projectId: number,
    advertiser: IDV360Advertiser
  ) {
    try {
      setSaving(true);
      if (advertiser.id) {
        const response = await DV360Resource.updateAdvertiser(
          advertiser.id,
          projectId,
          advertiser.advertiser_id,
          advertiser.advertiser_name
        );
        setAdvertiser(response);
      } else {
        const response = await DV360Resource.createAdvertiser(
          projectId,
          advertiser.advertiser_id,
          advertiser.advertiser_name
        );
        setAdvertiser(response);
      }
      enqueueSnackbar(successMessage, { variant: "success" });
    } catch (e) {
      enqueueSnackbar(axios500Error, { variant: "error" });
    } finally {
      setSaving(false);
    }
  }

  useEffect(() => {
    try {
      setLoading(true);
      fetchAdvertiser(props.project.id);
    } finally {
      setLoading(false);
    }
  }, [props.project.id]);

  return (
    <Paper elevation={1} sx={{ p: 2 }}>
      {loading && <CircularProgress />}
      {!loading && (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              fullWidth
              label="Advertiser ID"
              value={advertiser?.advertiser_id || ""}
              onChange={(event) =>
                setAdvertiser({
                  ...advertiser,
                  advertiser_id: event.target.value as unknown as number,
                })
              }
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              label="Advertiser name"
              value={advertiser?.advertiser_name || ""}
              onChange={(event) =>
                setAdvertiser({
                  ...advertiser,
                  advertiser_name: event.target.value,
                })
              }
            />
          </Grid>
          <Grid item>
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                startIcon={<Save />}
                color="success"
                disabled={saving}
                onClick={() =>
                  saveAdvertiser(
                    props.project.id,
                    advertiser as IDV360Advertiser
                  )
                }
              >
                Save
              </Button>
              <Button
                variant="outlined"
                disabled={saving}
                component={RouterLink}
                to={RoutePaths.PROJECTS}
                color="error"
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

export default ProjectEditDV360;

import api from "./index";
import {IProject} from "./projects";

export interface IGoogleAdsAccountRemote {
    id: number;
    descriptive_name: string;
    currency_code: string | null;
    timezone: string | null;
}

export interface IGoogleAdsAccount {
    id: number
    account_id: string
    account_name: string,
    project: IProject
}

export interface IGoogleAdsCampaignRemote {
    campaign_id: string
    name: string
    advertising_channel_type: string
    status: string

}

export interface IGoogleAdsCampaign {
    campaign_id: string
    name: string
    advertising_channel_type: string
    status: string
    scenario: {
        id: number;
        name: number;
        active: boolean;
        deleted: boolean;
    }
}

export interface IGoogleAdsCreatePayload {
    campaign_id: string
    campaign_name: string
    advertising_channel_type: string
    status: string
    scenario_id: number
}

export default class GoogleAdsApi {
    static async listAccounts(projectId: number): Promise<IGoogleAdsAccountRemote[]> {
        const response = await api.get(`/projects/${projectId}/adsystem/google_ads/accounts/remote`);
        return response.data;
    }

    static async connectGoogleAdsAccountWithProject(accountId: number, accountName: string, projectId: number): Promise<IGoogleAdsAccount> {
        const response = await api.post(`/projects/${projectId}/adsystem/google_ads/accounts`, {
            account_id: accountId,
            account_name: accountName,
        })
        return response.data;
    }

    static async getGoogleAdsAccounts(projectId: number): Promise<IGoogleAdsAccount> {
        const response = await api.get(`/projects/${projectId}/adsystem/google_ads/accounts`);
        return response.data;
    }

    static async listRemoteCampaigns(projectId: number): Promise<IGoogleAdsCampaignRemote[]> {
        const response = await api.get(`/projects/${projectId}/adsystem/google_ads/campaigns/remote`);
        return response.data;
    }

    static async listCampaigns(projectId: number): Promise<IGoogleAdsCampaign[]> {
        const response = await api.get(`/projects/${projectId}/adsystem/google_ads/campaigns`);
        return response.data;
    }

    static async getCampaign(projectId: number, campaignId: string): Promise<IGoogleAdsCampaign> {
        const response = await api.get(`/projects/${projectId}/adsystem/google_ads/campaigns/${campaignId}`);
        return response.data;
    }

    static async createCampaign(projectId: number, payload: IGoogleAdsCreatePayload): Promise<IGoogleAdsCampaign> {
        const response = await api.post(`/projects/${projectId}/adsystem/google_ads/campaigns`, payload);
        return response.data;
    }

    static async updateCampaign(projectId: number, campaignId: string, payload: IGoogleAdsCreatePayload): Promise<IGoogleAdsCampaign> {
        const response = await api.put(`/projects/${projectId}/adsystem/google_ads/campaigns/${campaignId}`, payload);
        return response.data;

    }

    static async deleteCampaign(campaignId: string, projectId: number): Promise<void> {
        await api.delete(`/projects/${projectId}/adsystem/google_ads/campaigns/${campaignId}`);
    }
}
import React, { useState } from "react";
import RequireAuth from "routes/RequireAuth";
import AppLayout from "layouts/AppLayout";
import { Button, Grid, Skeleton, TextField, Typography } from "@mui/material";
import WeatherScenarioAPI, {
  ConditionGroupCombinators,
  ConditionOperators,
  GeotargetingTypes,
  IConditionGroup,
  IGeotarget,
  IWeatherScenarioCreate,
} from "api/scenarios";
import { useCurrentProject } from "hooks/projects";
import WeatherQueryBuilder from "components/WeatherQueryBuilder/WeatherQueryBuilder";
import { FIELD_NAMES } from "components/WeatherQueryBuilder/constants";
import { Save } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import { axios500Error, successMessage } from "content/weather";

const initialScenario: IWeatherScenarioCreate = {
  name: "",
  active: true,
  deleted: false,
  rule: {
    group: {
      combinator: ConditionGroupCombinators.AND,
      rules: [
        {
          field: FIELD_NAMES.status,
          operator: ConditionOperators.IN,
          value: ["800"],
        },
      ],
    },
    geotargeting: {
      type: GeotargetingTypes.COUNTRY,
      identifiers: ["PL"],
    },
  },
};

const WeatherEdit = () => {
  const [scenario, setScenario] =
    useState<IWeatherScenarioCreate>(initialScenario);
  const [currentProject] = useCurrentProject();
  const [loading, setLoading] = useState(false);

  const handleSaveScenario = async (scenario: IWeatherScenarioCreate) => {
    setLoading(true);
    try {
      const response = await WeatherScenarioAPI.createScenario(
        currentProject.id,
        scenario
      );
      enqueueSnackbar(successMessage, { variant: "success" });
    } catch (e) {
      enqueueSnackbar(axios500Error, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const setScenarioName = (name: string) => {
    setScenario((prevState) => ({
      ...prevState,
      name: name,
    }));
  };

  const setWeatherRule = (newRule: IConditionGroup) => {
    setScenario((prevState) => ({
      ...prevState,
      rule: { geotargeting: prevState.rule.geotargeting, group: newRule },
    }));
  };

  const setGeotarget = (newGeotarget: IGeotarget) => {
    setScenario((prevState) => ({
      ...prevState,
      rule: {
        geotargeting: newGeotarget,
        group: prevState.rule.group,
      },
    }));
  };

  const loadingScreen = (
    <Grid item xs={12}>
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="rectangular" height={440} />
    </Grid>
  );

  return (
    <RequireAuth>
      <AppLayout>
        <Grid container rowSpacing={2}>
          {loading && loadingScreen}

          {!loading && (
            <React.Fragment>
              <Grid item xs={12}>
                <Typography variant="h5">Scenario name</Typography>
                <TextField
                  variant="standard"
                  value={scenario.name}
                  onChange={(e) => setScenarioName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <WeatherQueryBuilder
                  weatherQuery={scenario.rule.group}
                  onWeatherQueryChange={(query) => setWeatherRule(query)}
                  geotarget={scenario.rule.geotargeting}
                  onGeotargetChange={(target) => setGeotarget(target)}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  startIcon={<Save />}
                  onClick={async () => await handleSaveScenario(scenario)}
                >
                  Save
                </Button>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </AppLayout>
    </RequireAuth>
  );
};

export default WeatherEdit;

import {IGoogleAdsAccountRemote} from "../api/google_ads";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Radio,
    RadioGroup
} from "@mui/material";
import React from "react";


interface GoogleAdsAccountModalProps {
    open: boolean;
    onClose: () => void;
    onConfirm: (selectedAccountId: number | null) => void;
    selectedAccountId: number | null;
    options: Array<IGoogleAdsAccountRemote>;
}

const GoogleAdsAccountModal = (props: GoogleAdsAccountModalProps) => {
    const radioGroupRef = React.useRef<HTMLElement>(null);
    const [value, setValue] = React.useState<number | null>(props.selectedAccountId);

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>Select Google Ads account to connect</DialogTitle>
            <DialogContent dividers>
                <RadioGroup
                    ref={radioGroupRef}
                    value={value}
                    onChange={(event) => {
                        setValue(parseInt(event.target.value, 10));
                    }}
                >
                    {props.options.map((option: IGoogleAdsAccountRemote) => (
                        <FormControlLabel
                            value={option.id}
                            key={option.id}
                            label={`${option.descriptive_name} (id: ${option.id})`}
                            control={<Radio/>}
                        />
                    ))}
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={props.onClose}>
                    Cancel
                </Button>
                <Button onClick={() => props.onConfirm(value)}>Ok</Button>
            </DialogActions>
        </Dialog>
    )
}

export default GoogleAdsAccountModal
import RequireAuth from "routes/RequireAuth";
import AppLayout from "layouts/AppLayout";
import {useEffect, useState} from "react";
import {GoogleAdsPlatformConnection, IGoogleAdsPlatformConnection} from "api/platform_connection";
import {Alert, CircularProgress, Grid, Typography} from "@mui/material";
import {Link} from "react-router-dom"
import GoogleAdsApi, {IGoogleAdsAccountRemote} from "../../../api/google_ads";
import GoogleAdsAccountModal from "../../../components/GoogleAdsAccountModal";


const ConnectGoogleAdsCallback = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const [isError, setError] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [availableAccounts, setAvailableAccounts] = useState<IGoogleAdsAccountRemote[]>([]);
    const [selectedAccountId, setSelectedAccountId] = useState<number | null>(null)
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const code = new URLSearchParams(window.location.search).get('code') ?? null;
    const projectId = Number(new URLSearchParams(window.location.search).get('state')?.slice('pid='.length)) ?? null;

    const exchangeAuthCode = async (code: string, projectId: number) => {
        try {
            setLoading(true);
            await GoogleAdsPlatformConnection.connect(code, Number(projectId));
            await loadAccounts(projectId);
        } catch (e) {
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    const loadAccounts = async (projectId: number) => {
        const accounts = await GoogleAdsApi.listAccounts(projectId);
        setAvailableAccounts(accounts);
        if (accounts.length > 0) {
            setModalIsOpen(true);
        }
    }

    const handleSelectAccount = async (accountId: number | null) => {
        setSaving(true)
        setSelectedAccountId(accountId)
        const account = availableAccounts.filter(account => account.id == accountId)[0]
        try {
             await GoogleAdsApi.connectGoogleAdsAccountWithProject(account.id, account.descriptive_name, projectId)
        } catch (e) {
            setError(true);
        }

        setModalIsOpen(false);
        setSaving(false);
        setSuccess(true);
    }

    useEffect(() => {
        let lock = false;
        if (!lock && code && projectId) {
            lock = true;
            try {
                exchangeAuthCode(code, projectId)
            } finally {
                lock = false;
            }
        }
    }, []);

    return (
        <RequireAuth>
            <AppLayout>
                {isError && <Grid item>
                    <Alert severity="error">
                        <Typography>There was an error while trying to connect your Google Account with Adstorm, <Link
                            to={`/projects/${projectId.toString()}/edit`}>try again</Link></Typography>
                    </Alert>
                </Grid>}
                {loading && <Grid container spacing={3} alignContent="center">
                    <Grid item>
                        <Typography variant="h4"><CircularProgress/> Please wait while we're connecting your Google Ads
                            with AdStorm</Typography>
                    </Grid>
                </Grid>}
                {saving && <Grid container spacing={3} alignContent="center">
                    <Grid item>
                        <Typography variant="h4"><CircularProgress/> We're connecting your Google Ads account {selectedAccountId}</Typography>
                    </Grid>
                </Grid>}
                {success && <Grid container spacing={3} alignContent="center">
                    <Grid item>
                        <Alert severity="success">
                            <Typography>Your Google Ads account has been connected with AdStorm!</Typography>
                        </Alert>
                    </Grid>
                </Grid>}
                {!loading && !isError && availableAccounts.length > 0 && <Grid container spacing={3} alignContent="center">
                    <GoogleAdsAccountModal options={availableAccounts} open={modalIsOpen} selectedAccountId={selectedAccountId} onClose={() => setModalIsOpen(false)} onConfirm={handleSelectAccount} />
                </Grid>}
            </AppLayout>
        </RequireAuth>
    )
}

export default ConnectGoogleAdsCallback
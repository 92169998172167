import { IProject } from "./projects";
import api from "./index";
import { ILocation } from "./locations";

export enum GeotargetingTypes {
  COUNTRY = "country_code",
  CITY = "identifier",
}

export enum ConditionGroupCombinators {
  AND = "and",
  OR = "or",
  NOT = "not",
}

export enum ConditionOperators {
  EQUAL = "equal",
  NOT_EQUAL = "not_equal",
  LESS = "less_than",
  GREATER = "greater_than",
  LESS_EQUAL = "less_equal",
  GREATER_EQUAL = "greater_equal",
  IN = "in",
  NOT_IN = "not_in",
}

export interface ICondition {
  field: string;
  operator: ConditionOperators;
  value: string[] | number[] | string | number | boolean;
}

export interface IConditionGroup {
  combinator: ConditionGroupCombinators;
  rules: Array<ICondition | IConditionGroup>;
}

export interface IGeotarget {
  type: GeotargetingTypes;
  identifiers: string[] | number[] | ILocation[];
}

export interface IWeatherRule {
  group: IConditionGroup;
  geotargeting: IGeotarget;
}

export interface IWeatherScenario {
  id?: number;
  name?: string;
  project?: IProject;
  active: boolean;
  deleted: boolean;
  rule: IWeatherRule;
}

export interface IWeatherScenarioCreate {
  name: string;
  active: boolean;
  deleted: boolean;
  rule: IWeatherRule;
}

export default class WeatherScenarioAPI {
  static async listScenarios(
    projectId: number,
    withInactive: boolean = true
  ): Promise<IWeatherScenario[]> {
    const response = await api.get(
      `${projectId}/scenarios?with_inactive=${withInactive}`
    );
    return response.data as IWeatherScenario[];
  }

  static async getScenario(
    scenarioId: number,
    projectId: number
  ): Promise<IWeatherScenario> {
    const response = await api.get(`${projectId}/scenarios/${scenarioId}`);
    return response.data as IWeatherScenario;
  }

  static async createScenario(
    projectId: number,
    scenario: IWeatherScenarioCreate
  ): Promise<IWeatherScenario> {
    const identifiers = scenario.rule.geotargeting.identifiers.map(
      (identifier) => {
        if (typeof identifier === "string" || typeof identifier === "number") {
          return identifier;
        } else {
          return identifier.identifier;
        }
      }
    );
    const payload = {
      name: scenario.name,
      active: scenario.active,
      deleted: scenario.deleted,
      rule: {
        group: scenario.rule.group,
        geotargeting: {
          type: scenario.rule.geotargeting.type,
          identifiers: identifiers,
        },
      },
    };
    const response = await api.post(`${projectId}/scenarios`, payload);
    return response.data as IWeatherScenario;
  }

  static async updateScenario(
    scenarioId: number,
    projectId: number,
    scenario: IWeatherScenario
  ): Promise<IWeatherScenario> {
    const identifiers = scenario.rule.geotargeting.identifiers.map(
      (identifier) => {
        if (typeof identifier === "string" || typeof identifier === "number") {
          return identifier;
        } else {
          return identifier.identifier;
        }
      }
    );
    const payload = {
      name: scenario.name,
      active: scenario.active,
      deleted: scenario.deleted,
      rule: {
        group: scenario.rule.group,
        geotargeting: {
          type: scenario.rule.geotargeting.type,
          identifiers: identifiers,
        },
      },
    };
    const response = await api.put(
      `${projectId}/scenarios/${scenarioId}`,
      payload
    );
    return response.data as IWeatherScenario;
  }

  static async deactivateScenario(
    scenarioId: number,
    projectId: number
  ): Promise<IWeatherScenario> {
    return await api.patch(`${projectId}/scenarios/${scenarioId}`, {
      active: false,
    });
  }
  static async activateScenario(
    scenarioId: number,
    projectId: number
  ): Promise<IWeatherScenario> {
    return await api.patch(`${projectId}/scenarios/${scenarioId}`, {
      active: true,
    });
  }
}

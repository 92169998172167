import { OptionGroup } from "react-querybuilder";
import { ConditionOperators } from "api/scenarios";

export const COMBINATORS = [
  { name: "and", label: "AND" },
  { name: "or", label: "OR" },
  { name: "not", label: "NOT" },
];

export const OPERATORS = {
  EQUAL: { name: ConditionOperators.EQUAL, label: "is" },
  NOT_EQUAL: { name: ConditionOperators.NOT_EQUAL, label: "is not" },
  LESS_THAN: { name: ConditionOperators.LESS, label: "is less than" },
  GREATER_THAN: { name: ConditionOperators.GREATER, label: "is greater than" },
  LESS_EQUAL: {
    name: ConditionOperators.LESS_EQUAL,
    label: "is less or equal than",
  },
  GREATER_EQUAL: {
    name: ConditionOperators.GREATER_EQUAL,
    label: "is greater or equal than",
  },
  IN: { name: ConditionOperators.IN, label: "is one of" },
  NOT_IN: { name: ConditionOperators.NOT_IN, label: "is not one of" },
};

export const WEATHER_CONDITIONS = [
  {
    label: "Thunderstorm",
    options: [
      { name: "200", label: "thunderstorm with light rain" },
      { name: "201", label: "thunderstorm with rain" },
      { name: "202", label: "thunderstorm with heavy rain" },
      { name: "210", label: "light thunderstorm" },
      { name: "211", label: "thunderstorm" },
      { name: "212", label: "heavy thunderstorm" },
      { name: "221", label: "ragged thunderstorm" },
      { name: "230", label: "thunderstorm with light drizzle" },
      { name: "231", label: "thunderstorm with drizzle" },
      { name: "232", label: "thunderstorm with heavy drizzle" },
    ],
  },
  {
    label: "Drizzle",
    options: [
      { name: "300", label: "light intensity drizzle" },
      { name: "301", label: "drizzle" },
      { name: "302", label: "heavy intensity drizzle" },
      { name: "310", label: "light intensity drizzle rain" },
      { name: "311", label: "drizzle rain" },
      { name: "312", label: "heavy intensity drizzle rain" },
      { name: "313", label: "shower rain and drizzle" },
      { name: "314", label: "heavy shower rain and drizzle" },
      { name: "321", label: "shower drizzle" },
    ],
  },
  {
    label: "Rain",
    options: [
      { name: "500", label: "light rain" },
      { name: "501", label: "moderate rain" },
      { name: "502", label: "heavy intensity rain" },
      { name: "503", label: "very heavy rain" },
      { name: "504", label: "extreme rain" },
      { name: "511", label: "freezing rain" },
      { name: "520", label: "light intensity shower rain" },
      { name: "521", label: "shower rain" },
      { name: "522", label: "heavy intensity shower rain" },
      { name: "531", label: "ragged shower rain" },
    ],
  },
  {
    label: "Snow",
    options: [
      { name: "600", label: "light snow" },
      { name: "601", label: "snow" },
      { name: "602", label: "heavy snow" },
      { name: "611", label: "sleet" },
      { name: "612", label: "light shower sleet" },
      { name: "613", label: "shower sleet" },
      { name: "615", label: "light rain and snow" },
      { name: "616", label: "rain and snow" },
      { name: "620", label: "light shower snow" },
      { name: "621", label: "shower snow" },
      { name: "622", label: "heavy shower snow" },
    ],
  },
  {
    label: "Atmosphere",
    options: [
      { name: "701", label: "mist" },
      { name: "711", label: "smoke" },
      { name: "721", label: "haze" },
      { name: "731", label: "sand/ dust whirls" },
      { name: "741", label: "fog" },
      { name: "751", label: "sand" },
      { name: "761", label: "dust" },
      { name: "762", label: "volcanic ash" },
      { name: "771", label: "squalls" },
      { name: "781", label: "tornado" },
    ],
  },
  {
    label: "Clouds",
    options: [
      { name: "800", label: "clear sky" },
      { name: "801", label: "few clouds: 11-25%" },
      { name: "802", label: "scattered clouds: 25-50%" },
      { name: "803", label: "broken clouds: 51-84%" },
      { name: "804", label: "overcast clouds: 85-100%" },
    ],
  },
];

export enum FIELD_NAMES {
  status = "status",
  visibility = "visibility",
  wind_speed = "wind_speed",
  temperature = "temperature",
  temperature_feel = "temperature_feel",
  temperature_max = "temperature_max",
  temperature_min = "temperature_min",
  pressure = "pressure",
  cloudiness = "cloudiness",
  rain = "rain",
  snow = "snow",
}

export const FIELDS = [
  {
    name: FIELD_NAMES.status,
    label: "Weather condition",
    operators: [
      { name: ConditionOperators.IN, label: "is one of" },
      { name: ConditionOperators.NOT_IN, label: "is not one of" },
    ],
    values: WEATHER_CONDITIONS as unknown as OptionGroup[],
    valueEditorType: "multiselect",
    placeholder: "Rainy, sunny, etc.",
  },
  {
    name: FIELD_NAMES.visibility,
    label: "Air visibility",
    inputType: "number",
    operators: [
      { name: ConditionOperators.EQUAL, label: "is" },
      { name: ConditionOperators.NOT_EQUAL, label: "is not" },
      { name: ConditionOperators.LESS, label: "is less than" },
      { name: ConditionOperators.GREATER, label: "is greather than" },
      {
        name: ConditionOperators.GREATER_EQUAL,
        label: "is greater or equal to",
      },
      { name: ConditionOperators.LESS_EQUAL, label: "is less or equal to" },
    ],
  },
  {
    name: FIELD_NAMES.wind_speed,
    label: "Wind speed (km/h)",
    inputType: "number",
    operators: [
      { name: ConditionOperators.EQUAL, label: "is" },
      { name: ConditionOperators.NOT_EQUAL, label: "is not" },
      { name: ConditionOperators.LESS, label: "is less than" },
      { name: ConditionOperators.GREATER, label: "is greather than" },
      {
        name: ConditionOperators.GREATER_EQUAL,
        label: "is greater or equal to",
      },
      { name: ConditionOperators.LESS_EQUAL, label: "is less or equal to" },
    ],
  },
  {
    name: FIELD_NAMES.temperature,
    label: "Temperature",
    inputType: "number",
    operators: [
      { name: ConditionOperators.EQUAL, label: "is" },
      { name: ConditionOperators.NOT_EQUAL, label: "is not" },
      { name: ConditionOperators.LESS, label: "is less than" },
      { name: ConditionOperators.GREATER, label: "is greather than" },
      {
        name: ConditionOperators.GREATER_EQUAL,
        label: "is greater or equal to",
      },
      { name: ConditionOperators.LESS_EQUAL, label: "is less or equal to" },
    ],
  },
  {
    name: FIELD_NAMES.temperature_feel,
    label: "Temperature - feeled",
    inputType: "number",
    operators: [
      { name: ConditionOperators.EQUAL, label: "is" },
      { name: ConditionOperators.NOT_EQUAL, label: "is not" },
      { name: ConditionOperators.LESS, label: "is less than" },
      { name: ConditionOperators.GREATER, label: "is greather than" },
      {
        name: ConditionOperators.GREATER_EQUAL,
        label: "is greater or equal to",
      },
      { name: ConditionOperators.LESS_EQUAL, label: "is less or equal to" },
    ],
  },
  {
    name: FIELD_NAMES.temperature_max,
    label: "Max. daily temperature",
    inputType: "number",
    operators: [
      { name: ConditionOperators.EQUAL, label: "is" },
      { name: ConditionOperators.NOT_EQUAL, label: "is not" },
      { name: ConditionOperators.LESS, label: "is less than" },
      { name: ConditionOperators.GREATER, label: "is greather than" },
      {
        name: ConditionOperators.GREATER_EQUAL,
        label: "is greater or equal to",
      },
      { name: ConditionOperators.LESS_EQUAL, label: "is less or equal to" },
    ],
    defaultValue: 30,
  },
  {
    name: FIELD_NAMES.temperature_min,
    label: "Min. daily temperature",
    inputType: "number",
    operators: [
      { name: ConditionOperators.EQUAL, label: "is" },
      { name: ConditionOperators.NOT_EQUAL, label: "is not" },
      { name: ConditionOperators.LESS, label: "is less than" },
      { name: ConditionOperators.GREATER, label: "is greather than" },
      {
        name: ConditionOperators.GREATER_EQUAL,
        label: "is greater or equal to",
      },
      { name: ConditionOperators.LESS_EQUAL, label: "is less or equal to" },
    ],
    defaultValue: -10,
  },
  {
    name: FIELD_NAMES.pressure,
    label: "Pressure (hPa)",
    inputType: "number",
    operators: [
      { name: ConditionOperators.EQUAL, label: "is" },
      { name: ConditionOperators.NOT_EQUAL, label: "is not" },
      { name: ConditionOperators.LESS, label: "is less than" },
      { name: ConditionOperators.GREATER, label: "is greather than" },
      {
        name: ConditionOperators.GREATER_EQUAL,
        label: "is greater or equal to",
      },
      { name: ConditionOperators.LESS_EQUAL, label: "is less or equal to" },
    ],
    defaultValue: 1013,
  },
  {
    name: "humidity",
    label: "Humidity",
    inputType: "number",
    operators: [
      { name: ConditionOperators.EQUAL, label: "is" },
      { name: ConditionOperators.NOT_EQUAL, label: "is not" },
      { name: ConditionOperators.LESS, label: "is less than" },
      { name: ConditionOperators.GREATER, label: "is greather than" },
      {
        name: ConditionOperators.GREATER_EQUAL,
        label: "is greater or equal to",
      },
      { name: ConditionOperators.LESS_EQUAL, label: "is less or equal to" },
    ],
    defaultValue: 0,
  },
  {
    name: FIELD_NAMES.cloudiness,
    label: "Cloudiness %",
    inputType: "number",
    operators: [
      { name: ConditionOperators.EQUAL, label: "is" },
      { name: ConditionOperators.NOT_EQUAL, label: "is not" },
      { name: ConditionOperators.LESS, label: "is less than" },
      { name: ConditionOperators.GREATER, label: "is greather than" },
      {
        name: ConditionOperators.GREATER_EQUAL,
        label: "is greater or equal to",
      },
      { name: ConditionOperators.LESS_EQUAL, label: "is less or equal to" },
    ],
    defaultValue: 0,
  },
  {
    name: FIELD_NAMES.rain,
    label: "Rain fall (mm)",
    inputType: "number",
    operators: [
      { name: ConditionOperators.EQUAL, label: "is" },
      { name: ConditionOperators.NOT_EQUAL, label: "is not" },
      { name: ConditionOperators.LESS, label: "is less than" },
      { name: ConditionOperators.GREATER, label: "is greather than" },
      {
        name: ConditionOperators.GREATER_EQUAL,
        label: "is greater or equal to",
      },
      { name: ConditionOperators.LESS_EQUAL, label: "is less or equal to" },
    ],
    defaultValue: 0,
  },
  {
    name: FIELD_NAMES.snow,
    label: "Snow fall (mm)",
    inputType: "number",
    operators: [
      { name: ConditionOperators.EQUAL, label: "is" },
      { name: ConditionOperators.NOT_EQUAL, label: "is not" },
      { name: ConditionOperators.LESS, label: "is less than" },
      { name: ConditionOperators.GREATER, label: "is greather than" },
      {
        name: ConditionOperators.GREATER_EQUAL,
        label: "is greater or equal to",
      },
      { name: ConditionOperators.LESS_EQUAL, label: "is less or equal to" },
    ],
    defaultValue: 0,
  },
];

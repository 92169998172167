import {
  Button,
  Grid,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AppLayout from "layouts/AppLayout";
import React from "react";
import { IProject, ProjectAPI } from "api/projects";
import { KeyboardArrowDown } from "@mui/icons-material";
import RequireAuth from "routes/RequireAuth";
import { RoutePaths } from "routes";
import { Link as RouterLink } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { loadingError } from "content/projects";

class ProjectList extends React.Component {
  state = {
    manageMenuAnchorEl: null,
    loading: true,
    projects: [] as IProject[],
  };

  async componentDidMount() {
    try {
      const response = await ProjectAPI.getProjects();
      this.setState({ projects: response, loading: false });
    } catch {
      enqueueSnackbar(loadingError, { variant: "error" });
    } finally {
      this.setState({ loading: false });
    }
  }

  handleAnchorEl = (e: React.SyntheticEvent) => {
    this.setState({ manageMenuAnchorEl: e.currentTarget });
  };
  handleMenuClose = () => {
    this.setState({ manageMenuAnchorEl: null });
  };

  renderRows() {
    return this.state.projects.map((project) => (
      <TableRow key={project.id}>
        <TableCell>{project.id}</TableCell>
        <TableCell><RouterLink to={`${RoutePaths.PROJECTS}/${project.id}/edit`}>{project.name}</RouterLink></TableCell>
        <TableCell>{project.company.name}</TableCell>
        <TableCell>{project.active ? "Yes" : "No"}</TableCell>
        <TableCell align="right">
          <Button
            component={RouterLink}
            to={`${RoutePaths.PROJECTS}/${project.id}/edit`}
          >
            Edit
          </Button>
        </TableCell>
      </TableRow>
    ));
  }

  render() {
    return (
      <RequireAuth>
        <AppLayout>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4">Projects</Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                startIcon={<KeyboardArrowDown />}
                variant="contained"
                onClick={this.handleAnchorEl}
              >
                Manage
              </Button>
              <Menu
                open={Boolean(this.state.manageMenuAnchorEl)}
                anchorEl={this.state.manageMenuAnchorEl}
                onClose={this.handleMenuClose}
              >
                <MenuItem to={RoutePaths.COMPANY_CREATE} component={RouterLink}>
                  New company
                </MenuItem>
                <MenuItem to={RoutePaths.PROJECT_CREATE} component={RouterLink}>
                  New project
                </MenuItem>
              </Menu>
            </Grid>
            <Grid item xs>
              <TableContainer component={Paper}>
                {this.state.loading && <LinearProgress />}
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Company</TableCell>
                      <TableCell>Is active?</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  {!this.state.loading && (
                    <TableBody>{this.renderRows()}</TableBody>
                  )}
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </AppLayout>
      </RequireAuth>
    );
  }
}

export default ProjectList;

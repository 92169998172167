import React, { useEffect, useState } from "react";
import RequireAuth from "routes/RequireAuth";
import AppLayout from "layouts/AppLayout";
import {
  Button,
  ButtonGroup,
  Grid,
  LinearProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import DV360Resource, { IDV360LineItem } from "api/dv360";
import { useCurrentProject } from "hooks/projects";
import { isEmpty } from "lodash";
import {Add, Delete, Edit} from "@mui/icons-material";
import { RoutePaths } from "routes";
import { loadingError } from "content/adsystem/dv360";
import { enqueueSnackbar } from "notistack";

const DV360List = () => {
  const [dv360Campaigns, setDv360Campaigns] = useState<IDV360LineItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentProject] = useCurrentProject();

  const loadCampaigns = async () => {
    if (!isEmpty(currentProject)) {
      try {
        setLoading(true);
        const response = await DV360Resource.listLineItems(currentProject.id);
        setDv360Campaigns(response);
      } catch (e) {
        enqueueSnackbar(loadingError, { variant: "error" });
      } finally {
        setLoading(false);
      }
    }
  };

  const deleteCampaign = async (id: number) => {
    try {
      setLoading(true);
      await DV360Resource.deleteLineItem(id, currentProject.id);
      setDv360Campaigns(dv360Campaigns.filter((campaign) => campaign.id !== id));
    } catch (e) {
      enqueueSnackbar(loadingError, { variant: "error" });
    } finally {
      setLoading(false);
    }

  }

  useEffect(() => {
    loadCampaigns();
  }, [currentProject.id]);

  const tableRows = dv360Campaigns.map((campaign) => (
    <TableRow key={campaign.id}>
      <TableCell>{campaign.id}</TableCell>
      <TableCell>
        {campaign.advertiser.advertiser_name}{" "}
        <small>(ID {campaign.advertiser.advertiser_id})</small>
      </TableCell>
      <TableCell>
        {campaign.order_name} <small>(ID {campaign.order_id})</small>
      </TableCell>
      <TableCell>
        {campaign.line_item_name} <small>(ID {campaign.line_item_id})</small>
      </TableCell>
      <TableCell>
        <Link
          component={RouterLink}
          to={RoutePaths.WEATHER_EDIT.replace(
            ":scenarioId",
            campaign.scenario.id as unknown as string
          )}
        >
          {campaign.scenario.name}
        </Link>
      </TableCell>
      <TableCell align="right">
        <ButtonGroup variant="text">
          <Button
            component={RouterLink}
            to={RoutePaths.DV360_EDIT.replace(
              ":id",
              campaign.id as unknown as string
            )}
            color="primary"
            startIcon={<Edit />}
          />
          <Button color="error" onClick={() => deleteCampaign(campaign.id)} startIcon={<Delete />} />
        </ButtonGroup>
      </TableCell>
    </TableRow>
  ));

  return (
    <RequireAuth>
      <AppLayout>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">Display & Video 360</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              startIcon={<Add />}
              component={RouterLink}
              to={RoutePaths.DV360_CREATE}
            >
              Add line item
            </Button>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              {loading && <LinearProgress />}
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Advertiser</TableCell>
                    <TableCell>Order</TableCell>
                    <TableCell>Line Item</TableCell>
                    <TableCell>Weather Scenario</TableCell>
                    <TableCell align="right">Options</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{tableRows}</TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </AppLayout>
    </RequireAuth>
  );
};

export default DV360List;

import React, { useState } from "react";
import RequireAuth from "routes/RequireAuth";
import AppLayout from "layouts/AppLayout";
import {
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Switch,
  TextField,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import { CompanyAPI } from "api/projects";
import { RoutePaths } from "../../routes";
import { Save } from "@mui/icons-material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { axios500Error, successMessage } from "../../content/projects";

const CompanyCreate = () => {
  const [name, setName] = useState("");
  const [active, setActive] = useState(true);
  const [saving, setSaving] = useState(false);

  const navigate = useNavigate();

  const handleSave = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setSaving(true);

    try {
      await CompanyAPI.createCompany({
        name: name,
        active: active,
      });
      enqueueSnackbar(successMessage, { variant: "success" });
      navigate(RoutePaths.PROJECTS, { replace: true });
    } catch (error) {
      enqueueSnackbar(axios500Error, { variant: "error" });
    } finally {
      setSaving(false);
    }
  };

  return (
    <RequireAuth>
      <AppLayout>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4">Create new company</Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={1} sx={{ p: 2 }}>
              <form onSubmit={handleSave}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <TextField
                      fullWidth
                      label="Company name"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Grid>
                  <Grid item>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={active}
                            onChange={(e) =>
                              setActive(Boolean(e.target.checked))
                            }
                          />
                        }
                        label="Active"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid container justifyContent="flex-end">
                    <Stack direction="row" spacing={2}>
                      <Button
                        disabled={saving}
                        variant="outlined"
                        startIcon={<Save />}
                        color="success"
                        type="submit"
                      >
                        Create new
                      </Button>
                      <Button
                        disabled={saving}
                        variant="outlined"
                        component={RouterLink}
                        to={RoutePaths.PROJECTS}
                        color="error"
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </AppLayout>
    </RequireAuth>
  );
};

export default CompanyCreate;

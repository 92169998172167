import React, { useEffect, useState } from "react";
import RequireAuth from "routes/RequireAuth";
import AppLayout from "layouts/AppLayout";
import {
  FormControlLabel,
  FormGroup,
  Grid,
  CircularProgress,
  Paper,
  Switch,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Stack,
} from "@mui/material";
import { CompanyAPI, ICompany, ProjectAPI } from "api/projects";
import { RoutePaths } from "../../routes";
import { Save } from "@mui/icons-material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { axios500Error, successMessage } from "content/projects";

const ProjectCreate = () => {
  const [name, setName] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [active, setActive] = useState(true);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [companies, setCompanies] = useState<ICompany[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchCompanies() {
      const response = await CompanyAPI.getCompanies();
      setCompanies(response);
    }

    fetchCompanies();

    setLoading(false);
  }, []);

  const handleSave = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setSaving(true);
    setLoading(true);

    try {
      await ProjectAPI.create({
        name: name,
        companyId: companyId as unknown as number,
        active: active,
        deleted: false,
      });
      enqueueSnackbar(successMessage, { variant: "success" });
      navigate(RoutePaths.PROJECTS, { replace: true });
    } catch (error) {
      enqueueSnackbar(axios500Error, { variant: "error" });
    } finally {
      setSaving(false);
      setLoading(false);
    }
  };

  return (
    <RequireAuth>
      <AppLayout>
        {loading && (
          <Grid container>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <CircularProgress />
            </Grid>
          </Grid>
        )}
        {!loading && (
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h4">Create new project</Typography>
            </Grid>
            <Grid item xs={12}>
              <Paper elevation={1} sx={{ p: 2 }}>
                <form onSubmit={handleSave}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <TextField
                        fullWidth
                        label="Project name"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Grid>
                    <Grid item>
                      {companies && (
                        <FormControl fullWidth>
                          <InputLabel id="company">Company</InputLabel>
                          <Select
                            labelId="company"
                            value={companyId}
                            label="Company"
                            onChange={(e) => setCompanyId(e.target.value)}
                          >
                            {companies.map((company) => (
                              <MenuItem key={company.id} value={company.id}>
                                {company.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={active}
                              onChange={(e) =>
                                setActive(Boolean(e.target.checked))
                              }
                            />
                          }
                          label="Active"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid container justifyContent="flex-end">
                      <Stack direction="row" spacing={2}>
                        <Button
                          disabled={saving}
                          variant="outlined"
                          startIcon={<Save />}
                          color="success"
                          type="submit"
                        >
                          Create new
                        </Button>
                        <Button
                          disabled={saving}
                          variant="outlined"
                          component={RouterLink}
                          to={RoutePaths.PROJECTS}
                          color="error"
                        >
                          Cancel
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </form>
              </Paper>
            </Grid>
          </Grid>
        )}
      </AppLayout>
    </RequireAuth>
  );
};

export default ProjectCreate;

import React, {useEffect, useState} from "react";
import RequireAuth from "routes/RequireAuth";
import AppLayout from "layouts/AppLayout";
import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useCurrentProject} from "hooks/projects";
import DV360Resource from "api/dv360";
import {isEmpty} from "lodash";
import WeatherScenarioAPI, {IWeatherScenario} from "api/scenarios";
import {Save} from "@mui/icons-material";
import {RoutePaths} from "../../../routes";
import {enqueueSnackbar} from "notistack";
import {axios500Error, successMessage} from "content/adsystem/dv360";
import GoogleAdsApi, {IGoogleAdsCampaignRemote, IGoogleAdsCreatePayload} from "../../../api/google_ads";

const GoogleAdsCreate = () => {
  const [campaignPayload, setCampaignPayload] = useState<
    Partial<IGoogleAdsCreatePayload>
  >({});
  const [remoteCampaigns, setRemoteCampaigns] = useState<
    IGoogleAdsCampaignRemote[]
  >([]);
  const [weatherScenarios, setWeatherScenarios] = useState<IWeatherScenario[]>(
    []
  );
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingRemote, setLoadingRemote] = useState(false);
  const [loadingScenarios, setLoadingScenarios] = useState(false);
  const [saving, setSaving] = useState(false);
  const [currentProject] = useCurrentProject();

  const loadWeatherScenarios = async () => {
    try {
      setLoadingScenarios(true);
      const response = await WeatherScenarioAPI.listScenarios(
        currentProject.id,
        false
      );
      setWeatherScenarios(response);
    } finally {
      setLoadingScenarios(false);
    }
  };

  const loadRemoteCampaigns = async () => {
    try {
      setLoadingRemote(true);
      const remoteCampaigns = await GoogleAdsApi.listRemoteCampaigns(
        currentProject.id
      );
      setRemoteCampaigns(remoteCampaigns);
    } finally {
      setLoadingRemote(false);
    }
  };

  const saveCampaign = async () => {
    try {
      setSaving(true);
      if (!isEmpty(campaignPayload)) {
        await GoogleAdsApi.createCampaign(currentProject.id, campaignPayload as IGoogleAdsCreatePayload);
      }
      setSaving(false);
      enqueueSnackbar(successMessage, { variant: "success" });
      navigate(RoutePaths.GOOGLE_ADS_LIST);
    } catch (e) {
      enqueueSnackbar(axios500Error, { variant: "error" });
    } finally {
      setSaving(false);
    }
  };

  const handleCampaignChange = (e: SelectChangeEvent) => {
    const filteredCampaign = remoteCampaigns.filter(
      (campaign) => campaign.campaign_id == e.target.value
    )[0];
    setCampaignPayload((prevState) => ({
      ...prevState,
        campaign_id: filteredCampaign.campaign_id,
        campaign_name: filteredCampaign.name,
        advertising_channel_type: filteredCampaign.advertising_channel_type,
        status: filteredCampaign.status,
    }));
  };

  const handleScenarioChange = (e: SelectChangeEvent) => {
    setCampaignPayload((prevState) => ({
      ...prevState,
      scenario_id: Number(e.target.value),
    }));
  };

  useEffect(() => {
    if (!isEmpty(currentProject)) {
      loadRemoteCampaigns();
      loadWeatherScenarios();
    }
  }, [currentProject.id]);

  const campaigns = remoteCampaigns.map((campaign) => (
    <MenuItem key={campaign.campaign_id} value={campaign.campaign_id}>
      {campaign.name} (<small>ID {campaign.campaign_id}</small>)
    </MenuItem>
  ));

  const scenarios = weatherScenarios.map((scenario) => (
    <MenuItem key={scenario.id} value={scenario.id}>
      {scenario.name}
    </MenuItem>
  ));

  return (
    <RequireAuth>
      <AppLayout>
        <Grid container rowSpacing={2}>
          {loading && <CircularProgress />}
          <React.Fragment>
            <Grid item xs={12}>
              <Typography variant="h4">
                Assign weather scenario to a Google Ads campaign
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Alert severity="info">
                Select a campaign and a weather scenario from a list to be
                managed. AdStorm will automatically update geotargeting to match
                locations where the weather you defined is taking place.
              </Alert>
            </Grid>
            <Grid item xs={12}>
              {loadingRemote && <CircularProgress />}
              {!loadingRemote && !loading && (
                <React.Fragment>
                  <InputLabel id="campaign">Campaign</InputLabel>
                  <Select id="campaign" onChange={handleCampaignChange}>
                    {campaigns}
                  </Select>
                </React.Fragment>
              )}
              {loadingScenarios && <CircularProgress />}
              {!loadingScenarios && !loading && (
                <React.Fragment>
                  <InputLabel id="scenarios">Weather scenario</InputLabel>
                  <Select id="scenarios" onChange={handleScenarioChange}>
                    {scenarios}
                  </Select>
                </React.Fragment>
              )}
            </Grid>
          </React.Fragment>

          <Grid item xs={12}>
            {saving && <CircularProgress />}
            <Button
              variant="contained"
              startIcon={<Save />}
              onClick={saveCampaign}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </AppLayout>
    </RequireAuth>
  );
};

export default GoogleAdsCreate;

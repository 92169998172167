import React, {useEffect, useState} from "react";
import RequireAuth from "routes/RequireAuth";
import AppLayout from "layouts/AppLayout";
import {
  Button,
  ButtonGroup,
  Grid,
  LinearProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {useCurrentProject} from "hooks/projects";
import _, {isEmpty} from "lodash";
import {Add, Delete, Edit, Pause, PlayArrow, Warning, Cancel} from "@mui/icons-material";
import {RoutePaths} from "routes";
import {loadingError} from "content/adsystem/dv360";
import {enqueueSnackbar} from "notistack";
import GoogleAdsApi, {IGoogleAdsCampaign} from "../../../api/google_ads";

const GoogleAdsList = () => {
  const [campaigns, setCampaigns] = useState<IGoogleAdsCampaign[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentProject] = useCurrentProject();

  const loadCampaigns = async () => {
    if (!isEmpty(currentProject)) {
      try {
        setLoading(true);
        const response = await GoogleAdsApi.listCampaigns(currentProject.id);
        setCampaigns(response);
      } catch (e) {
        enqueueSnackbar(loadingError, { variant: "error" });
      } finally {
        setLoading(false);
      }
    }
  };

  const deleteCampaign = async (campaignId: string) => {
    try {
      setLoading(true);
      await GoogleAdsApi.deleteCampaign(campaignId, currentProject.id);
      setCampaigns(campaigns.filter((campaign) => campaign.campaign_id !== campaignId));
    } catch (e) {
      enqueueSnackbar(loadingError, { variant: "error" });
    } finally {
      setLoading(false);
    }

  }

  useEffect(() => {
    loadCampaigns();
  }, [currentProject.id]);

  const tableRows = campaigns.map((campaign) => (
    <TableRow key={campaign.campaign_id}>
      <TableCell>{campaign.campaign_id}</TableCell>
      <TableCell>
        {campaign.name}
      </TableCell>
      <TableCell>
        {_.capitalize(campaign.advertising_channel_type)}
      </TableCell>
      <TableCell>
        {campaign.status}
      </TableCell>
      <TableCell>
        <Link
          component={RouterLink}
          to={RoutePaths.WEATHER_EDIT.replace(
            ":scenarioId",
            campaign.scenario.id as unknown as string
          )}
        >
          {campaign.scenario.name}
        </Link>
      </TableCell>
      <TableCell align="right">
        <ButtonGroup variant="text">
          <Button
            component={RouterLink}
            to={RoutePaths.GOOGLE_ADS_EDIT.replace(
              ":id",
              campaign.campaign_id as string
            )}
            color="primary"
            startIcon={<Edit />}
          />
          <Button color="error" onClick={() => deleteCampaign(campaign.campaign_id)} startIcon={<Delete />} />
        </ButtonGroup>
      </TableCell>
    </TableRow>
  ));

  return (
    <RequireAuth>
      <AppLayout>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">Google Ads</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              startIcon={<Add />}
              component={RouterLink}
              to={RoutePaths.GOOGLE_ADS_CREATE}
            >
              Add campaign
            </Button>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              {loading && <LinearProgress />}
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Campaign ID</TableCell>
                    <TableCell>Campaign Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Weather Scenario</TableCell>
                    <TableCell align="right">Options</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{tableRows}</TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </AppLayout>
    </RequireAuth>
  );
};

export default GoogleAdsList;

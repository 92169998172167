import React, { useEffect, useState } from "react";
import RequireAuth from "routes/RequireAuth";
import AppLayout from "layouts/AppLayout";
import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useCurrentProject } from "hooks/projects";
import DV360Resource, {
  IDV360LineItem,
  IDV360LineItemUpdateCreatePayload,
  IDV360RemoteLineItem,
} from "api/dv360";
import { isEmpty } from "lodash";
import WeatherScenarioAPI, { IWeatherScenario } from "api/scenarios";
import { Save } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import {axios500Error, loadingError, successMessage} from "content/adsystem/dv360";

const Dv360Edit = () => {
  const [lineItem, setLineItem] = useState<IDV360LineItem | null>();
  const [lineItemPayload, setLineItemPayload] = useState<
    Partial<IDV360LineItemUpdateCreatePayload>
  >({});
  const [remoteLineItems, setRemoteLineItems] = useState<
    IDV360RemoteLineItem[]
  >([]);
  const [weatherScenarios, setWeatherScenarios] = useState<IWeatherScenario[]>(
    []
  );
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [loadingRemote, setLoadingRemote] = useState(false);
  const [loadingScenarios, setLoadingScenarios] = useState(false);
  const [saving, setSaving] = useState(false);
  const [currentProject] = useCurrentProject();

  const loadLineItem = async () => {
    try {
      setLoading(true);
      const lineItem = await DV360Resource.getLineItem(
          id as unknown as number,
          currentProject.id
      );
      setLineItem(lineItem);
      setLineItemPayload({
        line_item_id: lineItem.line_item_id,
        order_id: lineItem.order_id,
        line_item_name: lineItem.line_item_name,
        scenario_id: lineItem.scenario.id,
      });
    } catch (e) {
        enqueueSnackbar(loadingError, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const loadWeatherScenarios = async () => {
    try {
      setLoadingScenarios(true);
      const response = await WeatherScenarioAPI.listScenarios(
        currentProject.id,
        false
      );
      setWeatherScenarios(response);
    } finally {
      setLoadingScenarios(false);
    }
  };

  const loadRemoteLineItems = async () => {
    try {
      setLoadingRemote(true);
      const remoteLineItems = await DV360Resource.listRemoteLineItems(
        currentProject.id
      );
      setRemoteLineItems(remoteLineItems);
    } finally {
      setLoadingRemote(false);
    }
  };

  const saveLineItem = async () => {
    try {
      setSaving(true);
      if (!isEmpty(lineItemPayload)) {
        const response = await DV360Resource.updateLineItem(
          currentProject.id,
          Number(id),
          {
            line_item_id: lineItemPayload.line_item_id as number,
            line_item_name: lineItemPayload.line_item_name as string,
            order_id: lineItemPayload.order_id as number,
            scenario_id: lineItemPayload.scenario_id as number,
          }
        );
        enqueueSnackbar(successMessage, { variant: "success" });
        setLineItem(response);
      }
    } catch (e) {
      enqueueSnackbar(axios500Error, { variant: "error" });
    } finally {
      setSaving(false);
    }
  };

  const handleLineItemChange = (e: SelectChangeEvent) => {
    const filteredLineItem = remoteLineItems.filter(
      (li) => li.line_item_id == e.target.value
    )[0];
    setLineItemPayload((prevState) => ({
      ...prevState,
      order_id: Number(filteredLineItem.insertion_order_id),
      line_item_id: Number(filteredLineItem.line_item_id),
      line_item_name: filteredLineItem.display_name,
    }));
  };

  const handleScenarioChange = (e: SelectChangeEvent) => {
    setLineItemPayload((prevState) => ({
      ...prevState,
      scenario_id: Number(e.target.value),
    }));
  };

  useEffect(() => {
    if (!isEmpty(currentProject)) {
      loadLineItem();
      loadRemoteLineItems();
      loadWeatherScenarios();
    }
  }, [currentProject.id, id]);

  const lineItems = remoteLineItems.map((lineItem) => (
    <MenuItem key={lineItem.line_item_id} value={lineItem.line_item_id}>
      {lineItem.display_name} (<small>ID {lineItem.line_item_id}</small>)
    </MenuItem>
  ));

  const scenarios = weatherScenarios.map((scenario) => (
    <MenuItem key={scenario.id} value={scenario.id}>
      {scenario.name}
    </MenuItem>
  ));

  return (
    <RequireAuth>
      <AppLayout>
        <Grid container rowSpacing={2}>
          {loading && <CircularProgress />}
          {lineItem && (
            <React.Fragment>
              <Grid item xs={12}>
                <Typography variant="h4">{lineItem.line_item_name}</Typography>
                <Typography variant="subtitle1">
                  ID {lineItem?.line_item_id}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Alert severity="info">
                  Select a Line Item and a weather scenario from a list to be
                  managed. AdStorm will automatically update geotargeting to
                  match locations where the weather you defined is taking place.
                </Alert>
              </Grid>
              <Grid item xs={12}>
                {loadingRemote && <CircularProgress />}
                {!loadingRemote && !loading && (
                  <React.Fragment>
                    <InputLabel id="line-item">Line Item</InputLabel>
                    <Select
                      id="line-item"
                      value={lineItem.line_item_id as unknown as string}
                      onChange={handleLineItemChange}
                    >
                      {lineItems}
                    </Select>
                  </React.Fragment>
                )}
                {loadingScenarios && <CircularProgress />}
                {!loadingScenarios && !loading && (
                  <React.Fragment>
                    <InputLabel id="scenarios">Weather scenario</InputLabel>
                    <Select
                      id="scenarios"
                      value={lineItem.scenario?.id as unknown as string}
                      onChange={handleScenarioChange}
                    >
                      {scenarios}
                    </Select>
                  </React.Fragment>
                )}
              </Grid>
            </React.Fragment>
          )}
          <Grid item xs={12}>
            {saving && <CircularProgress />}
            <Button
              variant="contained"
              startIcon={<Save />}
              onClick={saveLineItem}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </AppLayout>
    </RequireAuth>
  );
};

export default Dv360Edit;

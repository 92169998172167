import React, { useEffect, useState } from "react";
import AppLayout from "layouts/AppLayout";
import RequireAuth from "routes/RequireAuth";
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Paper,
  LinearProgress,
  Button,
  TableHead,
  TableBody,
  ButtonGroup,
} from "@mui/material";
import { useCurrentProject } from "hooks/projects";
import WeatherScenarioAPI, { IWeatherScenario } from "api/scenarios";
import { isEmpty } from "lodash";
import { Add, Edit, Pause, PlayArrow } from "@mui/icons-material";
import { RoutePaths } from "../../routes";
import { Link as RouterLink } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { loadingError } from "content/weather";

const WeatherList = () => {
  const [scenarios, setScenarios] = useState<IWeatherScenario[]>([]);
  const [currentProject] = useCurrentProject();
  const [loading, setLoading] = useState(false);
  const loadWeatherScenarios = async () => {
    if (!isEmpty(currentProject)) {
      try {
        setLoading(true);
        const response = await WeatherScenarioAPI.listScenarios(
          currentProject.id
        );
        setScenarios(response);
      } catch (e) {
        enqueueSnackbar(loadingError, { variant: "error" });
      } finally {
        setLoading(false);
      }
    }
  };
  const deactivateScenario = async (scenarioId: number) => {
    await WeatherScenarioAPI.deactivateScenario(scenarioId, currentProject.id);
    await loadWeatherScenarios();
  };

  const activateScenario = async (scenarioId: number) => {
    await WeatherScenarioAPI.activateScenario(scenarioId, currentProject.id);
    await loadWeatherScenarios();
  };

  useEffect(() => {
    loadWeatherScenarios();
  }, [currentProject.id]);

  const tableRows = scenarios.map((scenario) => (
    <TableRow key={scenario.id}>
      <TableCell>{scenario.id}</TableCell>
      <TableCell>{scenario.name}</TableCell>
      <TableCell>{scenario.active ? "Yes" : "No"}</TableCell>
      <TableCell align="right">
        <ButtonGroup variant="text">
          <Button
            component={RouterLink}
            to={`${RoutePaths.WEATHER_EDIT.replace(
              ":scenarioId",
              scenario.id!.toString()
            )}`}
            color="primary"
            startIcon={<Edit />}
          />
          {scenario.active ? (
            <Button
              color="warning"
              startIcon={<Pause />}
              onClick={() => deactivateScenario(scenario.id!)}
            />
          ) : (
            <Button
              color="success"
              startIcon={<PlayArrow />}
              onClick={() => activateScenario(scenario.id!)}
            />
          )}
        </ButtonGroup>
      </TableCell>
    </TableRow>
  ));

  return (
    <RequireAuth>
      <AppLayout>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">Weather scenarios</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              startIcon={<Add />}
              component={RouterLink}
              to={RoutePaths.WEATHER_CREATE}
            >
              Add new scenario
            </Button>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              {loading && <LinearProgress />}
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Active</TableCell>
                    <TableCell align="right">Options</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{tableRows}</TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </AppLayout>
    </RequireAuth>
  );
};

export default WeatherList;

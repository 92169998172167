import api from "./index";

export interface ILocation {
  id: number;
  identifier: number;
  osm_id: number | null;
  display_name: string;
  country: string;
  country_code: string;
  county: string;
  state: string;
  region: string;
  city: string;
  latitude: number;
  longitude: number;
}

export interface ICountry {
  country: string;
  country_code: string;
}

export default class LocationAPI {
  static async listCountries(): Promise<ICountry[]> {
    const response = await api.get("/countries");
    return response.data;
  }

  static async searchLocation(name: string): Promise<ILocation[]> {
    const response = await api.get("/cities", { params: { search: name } });
    return response.data;
  }
}

import api from "./index";

export interface IGoogleAdsPlatformConnection {
    id: number;
    deleted: boolean;
    project_id: number;
    connected_by_user_id: number;
    platform: string;
}

export class GoogleAdsPlatformConnection {
    static async connect(authorizationCode: string, projectId: number): Promise<IGoogleAdsPlatformConnection> {
        const response = await api.post(`/projects/${projectId}/adsystem/google_ads/connect`, {
            authorization_code: authorizationCode,
        });
        return response.data;
    }
}
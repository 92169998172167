import { IProject } from "./projects";
import api from "./index";

export interface IDV360RemoteLineItem {
  advertiser_id: string;
  insertion_order_id: string;
  line_item_id: string;
  display_name: string;
}

export interface IDV360LineItemUpdateCreatePayload {
  order_id: number;
  line_item_id: number;
  line_item_name: string;
  scenario_id: number;
}

export interface IDV360Advertiser {
  id: number;
  advertiser_id: number;
  advertiser_name: string;
  active: boolean;
  deleted: boolean;
  project: IProject;
}

export interface IDV360LineItem {
  id: number;
  order_id: number;
  order_name: number;
  line_item_id: number;
  line_item_name: string;
  scenario: {
    id: number;
    name: number;
    active: boolean;
    deleted: boolean;
  };
  advertiser: IDV360Advertiser;
}

export default class DV360Resource {
  static async listLineItems(projectId: number): Promise<IDV360LineItem[]> {
    const response = await api.get(`/adsystem/dv360/p/${projectId}/campaigns`);
    return response.data;
  }

  static async getLineItem(
    id: number,
    projectId: number
  ): Promise<IDV360LineItem> {
    const response = await api.get(
      `/adsystem/dv360/p/${projectId}/campaigns/${id}`
    );
    return response.data;
  }

  static async listRemoteLineItems(
    projectId: number
  ): Promise<IDV360RemoteLineItem[]> {
    const response = await api.get(
      `/adsystem/dv360/p/${projectId}/remote-line-items`
    );
    return response.data;
  }

  static async updateLineItem(
    projectId: number,
    id: number,
    payload: IDV360LineItemUpdateCreatePayload
  ): Promise<IDV360LineItem> {
    const response = await api.put(
      `/adsystem/dv360/p/${projectId}/campaigns/${id}`,
      payload
    );
    return response.data;
  }

  static async createLineItem(
    projectId: number,
    payload: IDV360LineItemUpdateCreatePayload
  ): Promise<IDV360LineItem> {
    const response = await api.post(
      `/adsystem/dv360/p/${projectId}/campaigns`,
      payload
    );
    return response.data;
  }

  static async deleteLineItem(id: number, projectId: number): Promise<void> {
    await api.delete(`/adsystem/dv360/p/${projectId}/campaigns/${id}`);
  }

  static async getAdvertiser(projectId: number): Promise<IDV360Advertiser> {
    const response = await api.get(
      `/adsystem/dv360/p/${projectId}/advertisers`
    );
    return response.data;
  }

  static async createAdvertiser(
    projectId: number,
    advertiserId: number,
    advertiserName: string
  ): Promise<IDV360Advertiser> {
    const response = await api.post(
      `/adsystem/dv360/p/${projectId}/advertisers`,
      {
        advertiser_id: advertiserId,
        advertiser_name: advertiserName,
      }
    );
    return response.data;
  }

  static async updateAdvertiser(
    id: number,
    projectId: number,
    advertiserId: number,
    advertiserName: string
  ): Promise<IDV360Advertiser> {
    const response = await api.put(
      `/adsystem/dv360/p/${projectId}/advertisers/${id}`,
      {
        advertiser_id: advertiserId,
        advertiser_name: advertiserName,
      }
    );
    return response.data;
  }
}

import React from "react";
import BaseLayout from "./BaseLayout";
import Helmet from "react-helmet";

const BlankLayout = (props: React.PropsWithChildren<any>) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>AdStorm</title>
      </Helmet>
      <BaseLayout>{props.children}</BaseLayout>
    </React.Fragment>
  );
};

export default BlankLayout;

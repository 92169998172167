import { Box, Typography } from "@mui/material";
import BlankLayout from "layouts/BlankLayout";

export default function NotFound() {
  return (
    <BlankLayout>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h1">
          404
        </Typography>
        <Typography component="h2" variant="h2">
          Not found
        </Typography>
        <Typography>The page you requested was not found.</Typography>
      </Box>
    </BlankLayout>
  );
}

import React, {ReactNode} from "react";
import LoadingScreen from "components/LoadingScreen";
import HomeIcon from "@mui/icons-material/Home";
import {Business, Cloud, Google} from "@mui/icons-material";
import ProjectContainer from "views/ProjectContainer";

import Home from "views/Home";
import SignIn from "views/auth/SignIn";
import NotFound from "views/NotFound";
import Projects from "views/projects/ProjectList";
import ProjectUpdate from "views/projects/ProjectUpdate";
import ProjectCreate from "views/projects/ProjectCreate";
import CompanyCreate from "views/projects/CompanyCreate";
import WeatherScenarios from "views/weather/WeatherList";
import WeatherEdit from "views/weather/WeatherEdit";
import WeatherCreate from "views/weather/WeatherCreate";
import DV360List from "views/adsystem/dv360/DV360List";
import DV360Edit from "views/adsystem/dv360/DV360Edit";
import DV360Create from "views/adsystem/dv360/DV360Create";
import ConnectGoogleAdsCallback from "views/auth/platform_connection/ConnectGoogleAdsCallback";
import GoogleAdsList from "views/adsystem/google_ads/GoogleAdsList";
import GoogleAdsCreate from "views/adsystem/google_ads/GoogleAdsCreate";
import GoogleAdsEdit from "views/adsystem/google_ads/GoogleAdsEdit";

export enum RoutePaths {
    HOME = "/",
    LOGIN = "/login",
    NOT_FOUND = "*",
    PROJECTS = "/projects",
    PROJECT_DETAIL = "/projects/:projectId",
    PROJECT_UPDATE = "edit",
    PROJECT_CREATE = "/projects/create-new",
    COMPANY_CREATE = "/companies/create-new",
    WEATHER_SCENARIOS = "/weather-scenarios",
    WEATHER_CREATE = "/weather-scenarios/new",
    WEATHER_EDIT = "/weather-scenarios/:scenarioId",
    DV360_LIST = "/ads/dv360",
    DV360_EDIT = "/ads/dv360/:id",
    DV360_CREATE = "/ads/dv360/new",
    CONNECT_GOOGLE_ADS = "/auth/connect/google-ads",
    GOOGLE_ADS_LIST = "/ads/google-ads",
    GOOGLE_ADS_CREATE = "/ads/google-ads/new",
    GOOGLE_ADS_EDIT = "/ads/google-ads/:id",
}

export interface IRoute {
    element: JSX.Element;
    path: RoutePaths;
    children: IRoute[];
    meta?: {
        mainMenu: boolean;
        name: string;
        icon: ReactNode;
    };
}

const Routes: IRoute[] = [
    {
        element: (
            <React.Suspense fallback={<LoadingScreen/>}>
                <NotFound/>
            </React.Suspense>
        ),
        path: RoutePaths.NOT_FOUND,
        children: [],
    },
    {
        element: (
            <React.Suspense fallback={<LoadingScreen/>}>
                <Home/>
            </React.Suspense>
        ),
        path: RoutePaths.HOME,
        children: [],
        meta: {
            mainMenu: true,
            name: "Home",
            icon: <HomeIcon/>,
        },
    },
    {
        element: (
            <React.Suspense fallback={<LoadingScreen/>}>
                <SignIn/>
            </React.Suspense>
        ),
        path: RoutePaths.LOGIN,
        children: [],
    },
    {
        element: (
            <React.Suspense fallback={<LoadingScreen/>}>
                <Projects/>
            </React.Suspense>
        ),
        path: RoutePaths.PROJECTS,
        children: [],
        meta: {
            mainMenu: true,
            name: "Projects",
            icon: <Business/>,
        },
    },
    {
        element: <ProjectContainer/>,
        children: [
            {
                element: (
                    <React.Suspense fallback={<LoadingScreen/>}>
                        <ProjectUpdate/>
                    </React.Suspense>
                ),
                path: RoutePaths.PROJECT_UPDATE,
                children: [],
            },
        ],
        path: RoutePaths.PROJECT_DETAIL,
    },
    {
        element: (
            <React.Suspense fallback={<LoadingScreen/>}>
                <ProjectCreate/>
            </React.Suspense>
        ),
        path: RoutePaths.PROJECT_CREATE,
        children: [],
    },
    {
        element: (
            <React.Suspense fallback={<LoadingScreen/>}>
                <CompanyCreate/>
            </React.Suspense>
        ),
        path: RoutePaths.COMPANY_CREATE,
        children: [],
    },
    {
        element: (
            <React.Suspense fallback={<LoadingScreen/>}>
                <WeatherScenarios/>
            </React.Suspense>
        ),
        path: RoutePaths.WEATHER_SCENARIOS,
        children: [],
        meta: {
            mainMenu: true,
            name: "Weather Scenarios",
            icon: <Cloud/>,
        },
    },
    {
        element: (
            <React.Suspense fallback={<LoadingScreen/>}>
                <WeatherEdit/>
            </React.Suspense>
        ),
        path: RoutePaths.WEATHER_EDIT,
        children: [],
    },
    {
        element: (
            <React.Suspense fallback={<LoadingScreen/>}>
                <WeatherCreate/>
            </React.Suspense>
        ),
        path: RoutePaths.WEATHER_CREATE,
        children: [],
    },
    {
        element: (
            <React.Suspense fallback={<LoadingScreen/>}>
                <DV360List/>
            </React.Suspense>
        ),
        path: RoutePaths.DV360_LIST,
        children: [],
        meta: {
            mainMenu: true,
            name: "DV 360",
            icon: <Google/>,
        },
    },
    {
        element: (
            <React.Suspense fallback={<LoadingScreen/>}>
                <DV360Edit/>
            </React.Suspense>
        ),
        path: RoutePaths.DV360_EDIT,
        children: [],
    },
    {
        element: (
            <React.Suspense fallback={<LoadingScreen/>}>
                <DV360Create/>
            </React.Suspense>
        ),
        path: RoutePaths.DV360_CREATE,
        children: [],
    },
    {
        element: (
            <React.Suspense fallback={<LoadingScreen/>}>
                <ConnectGoogleAdsCallback/>
            </React.Suspense>
        ),
        path: RoutePaths.CONNECT_GOOGLE_ADS,
        children: [],
    },
    {
        element: (
            <React.Suspense fallback={<LoadingScreen/>}>
                <GoogleAdsList/>
            </React.Suspense>
        ),
        path: RoutePaths.GOOGLE_ADS_LIST,
        children: [],
        meta: {
            mainMenu: true,
            name: "Google Ads",
            icon: <Google/>,
        },
    },
    {
        element: (
            <React.Suspense fallback={<LoadingScreen/>}>
                <GoogleAdsCreate/>
            </React.Suspense>
        ),
        path: RoutePaths.GOOGLE_ADS_CREATE,
        children: [],
    },
    {
        element: (
            <React.Suspense fallback={<LoadingScreen/>}>
                <GoogleAdsEdit/>
            </React.Suspense>
        ),
        path: RoutePaths.GOOGLE_ADS_EDIT,
        children: [],
    }
];

export default Routes;

import QueryBuilder, {
  Field,
  NameLabelPair,
  RuleGroupTypeAny,
} from "react-querybuilder";
import { QueryBuilderMaterial } from "@react-querybuilder/material";
import React, { useEffect, useState } from "react";
import "assets/styles/index.scss";
import { COMBINATORS, FIELDS, OPERATORS } from "./constants";
import { GeotargetingTypes, IConditionGroup, IGeotarget } from "api/scenarios";
import LocationAPI, { ICountry, ILocation } from "api/locations";
import {
  Autocomplete,
  Box,
  Grid,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  ListSubheader,
  Radio,
  RadioGroup,
  Switch,
  TextareaAutosize,
  Button,
} from "@mui/material";
import { DragIndicator } from "@mui/icons-material";

type WeatherQueryBuilderProps = {
  weatherQuery: IConditionGroup;
  onWeatherQueryChange: (q: IConditionGroup) => void;
  geotarget: IGeotarget;
  onGeotargetChange: (target: IGeotarget) => void;
};

const muiComponents = {
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  ListSubheader,
  Radio,
  RadioGroup,
  Switch,
  Select,
  MenuItem,
  TextField,
  TextareaAutosize,
  Button,
  DragIndicator,
};

const WeatherQueryBuilder = (props: WeatherQueryBuilderProps) => {
  const combinators = COMBINATORS;
  const operators = OPERATORS as unknown as NameLabelPair[];
  const fields = FIELDS as unknown as Field[];
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [cities, setCities] = useState<ILocation[]>([]);

  const searchCity = async (value: string) => {
    if (value.length >= 2) {
      const response = await LocationAPI.searchLocation(value);
      setCities(response);
    }
  };
  useEffect(() => {
    const fetchCountries = async () => {
      setCountries(await LocationAPI.listCountries());
    };
    fetchCountries();
  }, []);

  const handleGeotargetTypeChange = (
    type: GeotargetingTypes,
    identifiers: string[] | number[] | ILocation[] = []
  ) => {
    props.onGeotargetChange({ type: type, identifiers: identifiers });
  };

  const countryGeoTarget = (
    <Select
      value={props.geotarget.identifiers}
      multiple
      fullWidth
      onChange={(e) => {
        let newValue = e.target.value as unknown as
          | string[]
          | number[]
          | ILocation[];
        if (typeof newValue === "string") {
          newValue = [newValue];
        }
        handleGeotargetTypeChange(GeotargetingTypes.COUNTRY, newValue);
      }}
    >
      {countries.map((country) => (
        <MenuItem
          key={country.country_code.toUpperCase()}
          value={country.country_code.toUpperCase()}
        >
          {country.country}
        </MenuItem>
      ))}
    </Select>
  );

  const cityGeoTarget = (
    <Autocomplete
      options={cities}
      multiple
      value={props.geotarget.identifiers as unknown as ILocation[]}
      getOptionLabel={(option) => option.display_name}
      groupBy={(option) => option.state}
      onChange={(e, newValue) =>
        handleGeotargetTypeChange(GeotargetingTypes.CITY, newValue)
      }
      onInputChange={(e, value) => searchCity(value)}
      renderInput={(params) => <TextField {...params} label="Cities" />}
    />
  );

  return (
    <Grid container rowSpacing={4}>
      <Grid item xs={10}>
        <Typography variant="h6">Weather conditions</Typography>
        <QueryBuilderMaterial muiComponents={muiComponents}>
          <QueryBuilder
            combinators={combinators}
            fields={fields}
            operators={operators}
            query={props.weatherQuery as unknown as RuleGroupTypeAny}
            onQueryChange={(q) =>
              props.onWeatherQueryChange(q as unknown as IConditionGroup)
            }
          />
        </QueryBuilderMaterial>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6">Geotargeting</Typography>
        <ToggleButtonGroup
          exclusive
          value={props.geotarget.type}
          onChange={(e, newGeotargetType) =>
            handleGeotargetTypeChange(newGeotargetType)
          }
        >
          <ToggleButton value={GeotargetingTypes.COUNTRY}>Country</ToggleButton>
          <ToggleButton value={GeotargetingTypes.CITY}>City</ToggleButton>
        </ToggleButtonGroup>
        <Box sx={{ marginTop: 1 }}>
          {props.geotarget.type === GeotargetingTypes.COUNTRY
            ? countryGeoTarget
            : cityGeoTarget}
        </Box>
      </Grid>
    </Grid>
  );
};

export default WeatherQueryBuilder;

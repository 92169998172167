import React, { useEffect, useState } from "react";
import {Grid, CircularProgress, Typography, Tabs, Tab} from "@mui/material";
import { IProject, ProjectAPI } from "api/projects";
import {Link, useParams} from "react-router-dom";
import ProjectEditOverview from "./edit/ProjectEditOverview";
import { set } from "lodash";
import TabPanel from "../../components/TabPanel";
import ProjectEditDV360 from "./edit/ProjectEditDV360";
import { enqueueSnackbar } from "notistack";
import { axios500Error, successMessage } from "content/projects";
import ProjectEditGoogleAdsManage from "./edit/ProjectEditGoogleAdsManage";

const ProjectUpdate = () => {
  const [project, setProject] = useState<IProject>({} as IProject);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const { projectId } = useParams();

  async function fetchProject() {
    const response = await ProjectAPI.get(projectId as unknown as number);
    setProject(response);
  }

  function handleProjectEdit(fieldName: string, value: any) {
    const updatedProject = set({ ...project }, fieldName, value);
    setProject(updatedProject);
  }

  useEffect(() => {
    try {
      setLoading(true);
      fetchProject();
    } finally {
      setLoading(false);
    }
  }, [projectId]);

  const handleSave = async () => {
    setSaving(true);
    setLoading(true);

    try {
      const response = await ProjectAPI.update(project.id as number, {
        name: project.name,
        companyId: project.company.id,
        active: project.active,
        deleted: project.deleted,
      });

      setProject(response);
      enqueueSnackbar(successMessage, { variant: "success" });
    } catch (error) {
      enqueueSnackbar(axios500Error, { variant: "error" });
    } finally {
      setSaving(false);
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      {loading && (
        <Grid container>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <CircularProgress />
          </Grid>
        </Grid>
      )}
      {!loading && (
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">Edit {project.name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Tabs
              value={currentTab}
              onChange={(e, value) => setCurrentTab(value)}
            >
              <Tab label="General" value={0} />
              <Tab label="DV 360" value={1} />
              <Tab label="Google Ads" value={2} />
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            <TabPanel index={0} value={currentTab}>
              {project.id && (
                <ProjectEditOverview
                  saving={saving}
                  project={project}
                  onSave={handleSave}
                  onUpdateField={(name, value) =>
                    handleProjectEdit(name, value)
                  }
                />
              )}
            </TabPanel>
            <TabPanel index={1} value={currentTab}>
              <ProjectEditDV360 project={project} />
            </TabPanel>
            <TabPanel index={2} value={currentTab}>
                <ProjectEditGoogleAdsManage project={project} />
            </TabPanel>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default ProjectUpdate;

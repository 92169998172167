import {
  Avatar,
  Box,
  Button,
  TextField,
  Typography,
  Alert,
} from "@mui/material";
import { LockOpenOutlined } from "@mui/icons-material";
import { SyntheticEvent, useState } from "react";
import Helmet from "react-helmet";
import BlankLayout from "layouts/BlankLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { RoutePaths } from "../../routes";
import AuthService from "../../services/auth/AuthService";

type LocationState = {
  state: {
    from: Location;
  };
};

const SignIn = () => {
  const [email, handleEmail] = useState("");
  const [password, handlePassword] = useState("");
  const [error, handleError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation() as unknown as LocationState;

  const from_location = location.state?.from?.pathname || RoutePaths.HOME;

  const handleForm = async (event: SyntheticEvent) => {
    event.preventDefault();
    handleError(false);
    try {
      await AuthService.login(email, password);
      navigate(from_location, { replace: true });
    } catch (e) {
      handleError(true);
    }
  };

  const LoginError = error && (
    <Alert severity="error">
      Can't login, email or password are incorrect.
    </Alert>
  );

  return (
    <BlankLayout>
      <Helmet>
        <title>Sign In</title>
      </Helmet>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOpenOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box
          component="form"
          noValidate
          sx={{ mt: 1 }}
          onSubmit={(event: SyntheticEvent) => handleForm(event)}
        >
          {LoginError}
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            type="email"
            value={email}
            onChange={(e) => handleEmail(e.target.value)}
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={password}
            onChange={(e) => handlePassword(e.target.value)}
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
        </Box>
      </Box>
    </BlankLayout>
  );
};

export default SignIn;

import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { Save } from "@mui/icons-material";
import { RoutePaths } from "../../../routes";
import React, { useEffect, useState } from "react";
import { CompanyAPI, ICompany, IProject } from "../../../api/projects";
import { Link as RouterLink } from "react-router-dom";

type onUpdateFieldType = (fieldName: string, value: any) => void;

interface ProjectEditOverviewProps {
  onSave: CallableFunction;
  onUpdateField: onUpdateFieldType;
  project: IProject;
  saving: boolean;
}

const ProjectEditOverview = (props: ProjectEditOverviewProps) => {
  const [companies, setCompanies] = useState<ICompany[]>([]);

  useEffect(() => {
    async function fetchCompanies() {
      const response = await CompanyAPI.getCompanies();
      setCompanies(response);
    }

    fetchCompanies();
  }, [props.project.id]);

  return (
    <Paper elevation={1} sx={{ p: 2 }}>
      <form onSubmit={() => props.onSave()}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              fullWidth
              label="Project name"
              required
              value={props.project.name}
              onChange={(e) => props.onUpdateField("name", e.target.value)}
            />
          </Grid>
          <Grid item>
            {props.project.company.id && (
              <FormControl fullWidth>
                <InputLabel id="company">Company</InputLabel>
                <Select
                  labelId="company"
                  value={props.project.company.id}
                  label="Company"
                  onChange={(e) =>
                    props.onUpdateField("company.id", e.target.value as number)
                  }
                >
                  {companies.map((company) => (
                    <MenuItem key={company.id} value={company.id}>
                      {company.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={props.project.active}
                    onChange={(e) =>
                      props.onUpdateField("active", Boolean(e.target.checked))
                    }
                  />
                }
                label="Active"
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={props.project.deleted}
                    onChange={(e) =>
                      props.onUpdateField("deleted", Boolean(e.target.checked))
                    }
                  />
                }
                label="Deleted"
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <Stack direction="row" spacing={2}>
              <Button
                disabled={props.saving}
                variant="outlined"
                startIcon={<Save />}
                color="success"
                type="submit"
              >
                Save
              </Button>
              <Button
                disabled={props.saving}
                variant="outlined"
                component={RouterLink}
                to={RoutePaths.PROJECTS}
                color="error"
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default ProjectEditOverview;

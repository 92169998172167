import RequireAuth from "../routes/RequireAuth";
import AppLayout from "../layouts/AppLayout";

const Home = () => {
  return (
    <RequireAuth>
      <AppLayout>index</AppLayout>
    </RequireAuth>
  );
};

export default Home;

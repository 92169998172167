import {IProject} from "api/projects";
import React, {useEffect, useState} from "react";
import {Alert, AlertTitle, Button, CircularProgress, Grid, Paper,} from "@mui/material";
import GoogleAdsApi, {IGoogleAdsAccount} from "api/google_ads";

interface ProjectEditGoogleAdsManage {
    project: IProject;
}

const ProjectEditGoogleAdsManage = (props: ProjectEditGoogleAdsManage) => {

    const oauthParams = {
        client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID ?? '',
        redirect_uri: process.env.REACT_APP_GOOGLE_OAUTH_REDIRECT_URI ?? '',
        response_type: 'code',
        scope: 'https://www.googleapis.com/auth/adwords',
        access_type: 'offline',
        prompt: 'consent',
        state: `pid=${props.project.id}`
    }
    const rootOauthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';

    const connectionLink = new URL(rootOauthUrl);
    Object.entries(oauthParams).forEach(([key, value]) => connectionLink.searchParams.append(key, value));

    const [loading, setLoading] = useState(true);
    const [account, setAccount] = useState<IGoogleAdsAccount | null>(null);
    const connectButtonMsg = account ? 'Reconnect Google Ads' : 'Connect Google Ads';

    useEffect(() => {
        async function fetchGoogleAdsAccount() {
            try {
                setLoading(true);
                const account = await GoogleAdsApi.getGoogleAdsAccounts(props.project.id);
                setAccount(account);
            } catch (e) {
                console.error(e);
            } finally {
                setLoading(false);
            }
        }

        fetchGoogleAdsAccount();
    }, [props.project.id])


    return (
        <Paper elevation={1} sx={{p: 2}}>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    {loading && <CircularProgress/>}
                    {!loading && account && <Alert severity="success">
                        <AlertTitle>Google Ads account connected</AlertTitle>
                        <p>
                            Your Google Ads
                            account <strong>{account.account_name} (ID: {account.account_id})</strong> is connected to
                            this project.
                        </p>
                    </Alert>
                    }
                    {!loading && !account && <Alert severity="info">
                        <AlertTitle>Connect your Google Ads account with AdStorm</AlertTitle>
                        <p>
                            AdStorm uses Google Ads API to manage your Google Ads campaigns and target
                            ads based on current weather conditions.
                            To connect your Google Ads account, click button below to authorize with your connected
                            Google account which has access to your Google Ads account and grant AdStorm
                            access to it.
                        </p>
                    </Alert>}
                </Grid>
                <Grid item>
                    <Button href={connectionLink.toString()} variant="outlined"
                            color="primary">{connectButtonMsg}</Button>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default ProjectEditGoogleAdsManage;
